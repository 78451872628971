<template>
  <div id="app">
    <!-- <Mapview>      </Mapview> -->
    <div id="allmap" style="overflow:hidden;zoom:1;position:relative;">
        <!-- </Mapview> -->
    <!-- <el-upload
      class="upload-demo"
      :action="uploadUrl"
      :on-change="handleFileChange"
      :before-upload="beforeUpload"
      multiple
      :file-list="fileList"
      accept=".geojson"
      style="position: absolute; bottom: 20px; right: 20px; z-index: 1000;">
      <el-button size="small" type="primary">点击上传</el-button>
      <div slot="tip" class="el-upload__tip">只能上传 .geojson 文件</div>
    </el-upload> -->
    </div>
      <div class="center-panel">数字底图构建工具</div>
      <!-- <div class="control-panel" v-if="showCascader">
        <el-cascader
          v-model="selectedValues"
          :options="options"
          @change="handleCascaderChange"
          placeholder="请选择"
          style="width: 300px; margin-top: 10px;"
        ></el-cascader>

        <el-button  type="primary" @click="confirmSelection" style="margin-top: 10px;">确认选择</el-button>
      </div> -->
      <!-- <el-button class="finishChild" v-if="showCascaderButton" @click="childset">子区圈选完成</el-button> -->
      <div class="floating-panel"  v-show="inandout">
        <div class="in-out-title">出入口设置</div>
      <el-button type="primary" @click="addSelect" class="add-button">添加出入口</el-button>
      <div v-for="(item, index) in selectList" :key="index" class="select-item">
        <span class="label">出入口 {{ index + 1 }} </span>
        
        <!-- 第一个选择框 -->
        <el-select class="custom-select" v-model="item.selected" placeholder="请选择" @change="updateSelections((index))">
          <el-option
            v-for="option in filteredPointsReback(index, false)"
            :key="option.coord.toString()"
            :label="option.id"
            :value="option.coord"
          ></el-option>
        </el-select>

        <!-- 第二个选择框 -->
        <el-select class="custom-select" v-model="item.selected2" placeholder="请选择" @change="updateSelections((index))">
          <el-option
            v-for="option in filteredPointsReback(index, false)"
            :key="option.coord.toString()"
            :label="option.id"
            :value="option.coord"
          ></el-option>
        </el-select>
        <el-button type="danger" @click="removeSelect(index)">删除</el-button>
      </div>
      <div class="dialog-footer">
    <div class="footer-buttons">
      <el-button @click="cancelSubmit">不设置出入口</el-button>
      <el-button type="primary" @click="submitSelections">确 定</el-button>
    </div>
  </div>
    </div>
        <div id="map" style="height:100%;-webkit-transition: all 0.5s ease-in-out;transition: all 0.5s ease-in-out;">
        </div>
          <div v-if="subdivideRegion" class="select-box">
            <el-select v-model="selectedLocation" placeholder="选择子区域" @change="handleSelectionChange" >
              <el-option
                v-for="(rectangle, index) in rectangles"
                :key="index"
                :label="rectangle.name"
                :value="rectangle.name"
              ></el-option>
            </el-select>
          </div>
          <div v-if="subdivideRegion" class="input-boxx">
            <el-input
              class="input-row-column"
              placeholder="切分列数"
              v-model="row"
              clearable>
            </el-input>
            <el-input
              class="input-row-column"
              placeholder="切分行数"
              v-model="column"
              clearable>
            </el-input>
            <el-button @click="handleCancel">取消</el-button>
            <el-button @click="handleBlur">确认</el-button>
          </div>
          <div v-if="this.active===0&&this.boolshow===0" class="searchdiv">
            <!-- <i class="el-icon-map-location"></i> -->
             <div  class="search-label">POI搜索</div> 
            <input v-model="searchQuery" id="tipinput"  @input="onInput" class="search-input"/>
          </div>
          <div v-if="this.boolshow===0" style="height: 35%;" class="steps">
            <el-steps direction="vertical" :active=active finish-status="success">
              <el-step title="开始构建"></el-step>
              <el-step title="区域名称"></el-step>
              <el-step title="区域边界"></el-step>
              <el-step title="子区域"></el-step>
              <el-step title="出入口"></el-step>
              <el-step title="子区细分"></el-step>
              <el-step title="数据导出"></el-step>
              <el-step title="完成构建"></el-step>
            </el-steps>
          </div>
          <div id="next-step" >
            <el-button  v-if="this.active !== 0" class="custom-button" @click="previousStep">上一步</el-button>
            <el-button v-if="this.boolshow===0" class="custom-button"@click="nextStep">{{ buttonText }}</el-button>
            <el-button  v-if="this.active === 0&&this.boolshow===0"class="custom-button" @click="importData">底图展示</el-button>

          </div>
          <el-upload
            class="upload-demo"
            :action="uploadUrl"
            :on-change="handleFileChange"
            :before-upload="beforeUpload"
            multiple
            :file-list="fileList"
            accept=".geojson"
            :show-file-list="false">
            <!-- <el-button  v-if="this.active === 0&&this.boolshow===0"class="custom-button" @click="importData">底图展示</el-button> -->

            <el-button  v-if="this.active ===0&&this.boolshow===1 "class="custom-button" @click="importData">底图导入</el-button>
            <!-- <div slot="tip" class="el-upload__tip">上传 .geojson 文件</div> -->
          </el-upload>
          <div class="reb">
            <el-button v-if="this.active === 0&&this.boolshow===1" class="custom-button" @click="canlImport">返回首页</el-button>
          </div>
          

          <div class="button-container">
            <!-- <el-button class="custom-button">导入</el-button> -->
            <!-- <el-button v-if="this.active !== 0&& this.active !== 2" class="custom-button" @click="rollback">回退</el-button> -->
            <!-- <el-button v-if="this.active === 0&&this.boolshow===1" class="custom-button" @click="canlImport">返回</el-button> -->
            <el-button v-if="this.active !== 0" class="custom-button" @click="rollback">回退</el-button>
            <el-button v-if="this.active !== 0" class="custom-button" @click="handleClick">{{ labelVisible ? '隐藏标签' : '显示标签' }}</el-button>
            <el-button v-if="this.active !== 0" class="custom-button" @click="resetData">重置</el-button>
          </div>
          <!-- <el-card v-if="this.active!==0" class="box-card">
            <div v-if="this.active===3">
              点击左键开始绘制边界<br>
              根据重构建筑的边界画多边形<br>
              双击左键结束绘制<br>
              绘制结束请点击下一步
            </div>
          </el-card> -->
          <!-- <el-tabs  :tab-position="'right'" v-model="activeName" @tab-click="handleClick" class="custom-tabs">
            <el-tab-pane @click="handlechoose('first')" label="区域名称" name="first" class="large-font"></el-tab-pane>
            <el-tab-pane @click="handlechoose('second')" label="边界" name="second" class="large-font"></el-tab-pane>
            <el-tab-pane @click="handlechoose('third')" label="子区域" name="third" class="large-font"></el-tab-pane>
            <el-tab-pane @click="handlechoose('fourth')" label="出入口" name="fourth" class="large-font"></el-tab-pane>
            <el-tab-pane @click="handlechoose('fifth')" label="子区细分" name="fifth" class="large-font"></el-tab-pane>
            <el-tab-pane @click="handlechoose('sixth')" label="导入" name="sixth" class="large-font"></el-tab-pane>
            <el-tab-pane @click="handlechoose('seventh')" label="导出" name="seventh" class="large-font"></el-tab-pane>
            <el-tab-pane @click="handlechoose('eighth')" label="重置" name="eighth" class="large-font"></el-tab-pane>
            <el-tab-pane @click="handlechoose('ninth')" :label="labelVisible ? '隐藏标签' : '显示标签' " name="ninth" class="large-font"></el-tab-pane>
            <el-tab-pane @click="handlechoose('tenth')" label="回退" name="tenth" class="large-font"></el-tab-pane>
            <el-tab-pane @click="handlechoose('tenth')" label="编辑" name="eleventh" class="large-font"></el-tab-pane>

          </el-tabs> -->
  </div>
</template>
<script src="https://webapi.amap.com/maps?v=2.0&key=您申请的key值&plugin=AMap.MouseTool"></script>

<script>
import Mapview from "./components/MapContainer.vue"
import axios from 'axios';
import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  name: 'App',
  components: {
    Mapview
  },
  data() {
    return {
      showCascader: false,
      showCascaderButton:false,
      inandout: false,
      subdivideRegion:false,
      choicevalue:"",//0,1,2
      areaName:"",
      row:"",
      column:"",
      base:0,//记录点的基础的
      labeledPoints: new Set(), // 用于存储已添加标签的点
      areaNamBoundary:"",
      areaNameAisle:"",
      areaNameEntranceExit:"",
      areaNameSubarea:"",
      cascaderValue:"",
      areaCenterPoints:[],
      polygonPoints : [],
      rectangles:[],//用于存储命名后的area的矩形数组，子区细分部分
      polygonData:[],
      map: null,
      overlays: [],
      drawingManager: null,
      styleOptions: {
        strokeColor: "red",
        fillColor: "red",
        strokeWeight: 3,
        strokeOpacity: 0.8,
        fillOpacity: 0.6,
        strokeStyle: 'solid'
      },
      ret:{},//第一次调用后端shapely后返回的函数
      retTwo:{},//子区划分调用后返回的函数，只能存储一个
      selectedLocation: [],
      locations: [
        { value: 'location1', label: '位置 1' },
        { value: 'location2', label: '位置 2' },
        { value: 'location3', label: '位置 3' },
      ],
      // rectangles:[],//矩形子模块命名加点位
      points: {
        pointA: { lng: null, lat: null },
        pointB: { lng: null, lat: null },
        pointC: { lng: null, lat: null },
        pointD: { lng: null,  lat: null }
      },
      temporary:[],
      allPoints:[], //data 中定义 polygons 数组
      pointsReback:[],//
      pointsRebackCopy:[],//用来赋值上一个字段的
      selectList: [{ selected: null }], // 初始选择器
      childrenoptions: [ // 可选项
        { value: 'option1', label: '1' },
        { value: 'option2', label: '2' },
        { value: 'option3', label: '3' },
      ],
      options: [
        {
          value: 'A',
          label: '手动圈选',
          children: [
            { value: 'A1', label: '吸附边缘' },
            { value: 'A2', label: '不吸附' }
          ]
        },
        {
          value: 'B',
          label: '自动划分',

        }
      ],
      textLabels:[],
      textCenterLabels:[],//区域的中心点
      activeName: '', // 默认激活的标签
      labelVisible:true,//用于地图标签初始化 显示
      lastTextNumber:0,//在回撤时，需要用来确定需要回撤标签的数量
      lastLineNumber:0,//用于存储回撤画的子区细分
      lastChildpolygonNumber:0,//用于存储回撤画的子区细分
      intersectingLine:[],
      intersectingLinePrepared:[],//用于存储画过的
      active:0,
      finalStep:0,//一直下一步，应该与active的值一样，this.active的值减少，这个不变
      stepBool:false,
      boundary:[],//单独用来存储导出的数据。边界
      aisle:[],//过道
      exit:[],//出入口,这个好弄selectList
      subarea:[],//细分区域.
      numstep:0,//用来区分进栈的步骤
      uploadUrl: '', // 上传地址 (可以留空，因为我们不上传到服务器)
      fileList: [], // 存储选择的文件
      drawLine:[],
      isDrawing:true,
      importBool:false,//用来判断是否导入过
      searchQuery: '',
      autoComplete: null,
      placeSearch: null,
      scale: null,//比例尺
      entrancesAndExits:[],//用来存储出入口画的线段的数据
      entrancesAndExitsCopy:[],//用来出入口画的线段的数据,在回退时使用
      drawLineBool:false,//判断从出入口回到画子区域那一步，有画新的子块,false 没画
      addExtenChild:false,//回退到添加子区域，判断是否加了新的。
      markers: [], // 存储搜索结果的标记
      boolshow:0,//用来标记底图展示的
    };
  },
  mounted() {
      this.initMap();
  },
  computed:{
    buttonText() {
      if (this.active === 0) {
        return '开始构建'; // active 为 0 时显示 "开始"
      } else if (this.active === 7) {
        return '完成'; // active 为 8 时显示 "完成"
      } else {
        return '下一步'; // 其它情况显示 "下一步"
      }
    }
  },
  methods: {
    // handleClick(){ 
    //   this.handleChoose(this.activeName);
    // },
    handleChoose(num){
      console.log("num",num);
      
      switch(num){
        case 'first': this.setAreaName(); // 确保这是一个方法调用
          break;
        case 'second':this.setBoundary(); // 确保这是一个方法调用
          break;
        case 'third':this.showCascader = true ; // 确保这是一个方法调用
          break;
        case 'fourth':this.inandout = true;
                      this.showCascaderButton=false; // 确保这是一个方法调用
          break;
        case 'fifth':this.handleSubdivide(); // 确保这是一个方法调用
                      this.showCascaderButton=false;
          break;
        case 'sixth':this.importData(); // 确保这是一个方法调用
          break;
        case 'seventh':this.exportData(); // 确保这是一个方法调用
          break;
        case 'eighth':this.reset(); // 确保这是一个方法调用
                      this.showCascaderButton=false;
          break;
        case 'ninth':this.hide();
          break;
        case 'tenth':this.rollback();
          break;
        case 'eleventh':this.rollback();
          break;
      }

    },
    toggleCascader(){
      this.showCascader = true; // 设置 showCascader 的值
      console.log(this.showCascader); // 输出 showCascader 的值
    },
    initMap() {
      console.log("初始化成功")
      // AMapLoader.reset()
      window._AMapSecurityConfig = {
        securityJsCode: "a430f608bf068cee59ae33ac8268a329",
      };
      AMapLoader.load({
        key: "2781d6dd22a0848f2362c76d52c8f05f", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Scale","AMap.MouseTool"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.map = new AMap.Map("allmap", {
            // 设置地图容器id
            resizeEnable: true,
            expandZoomRange:true,
            zoom:18, //设置初始化级别
            zooms:[3,20], //设置缩放级别范围 3-20 级
            center: [116.281138,39.878109], // 初始化地图中心点位置
          }); 
        // 输入提示和地点查询
        AMap.plugin(['AMap.PlaceSearch', 'AMap.AutoComplete'], () => {
          this.autoComplete = new AMap.AutoComplete({
            input: "tipinput",
          });

          this.placeSearch = new AMap.PlaceSearch({
            //map: this.map, //点标记
            city: "全国", // 默认查询全国
          });

          // 注册选择事件
          this.autoComplete.on("select", this.onSelect);
        });
          this.scale = new AMap.Scale();
          this.map.addControl(this.scale);
          this.scale.show();
          this.map.setMapStyle("amap://styles/whitesmoke");
          this.drawingManager = new AMap.MouseTool(this.map); 
        //监听draw事件可获取画好的覆盖物
        this.overlays = [];
        this.drawingManager.on('draw',this.overlayComplete) 
        //this.draw('polygon');
          })
          .catch((e) => {
            console.log(e);
          });
          
    },
    toggleDrawing() {
      
      if (this.isDrawing) {
        this.drawingManager.polygon(); // 或其他绘图类型
      } else {
        this.drawingManager.close(); // 关闭并清除覆盖物
        this.overlays = [];
      }
    },
    draw(){
          console.log("draw");     
          this.drawingManager.polygon({
              fillColor:'#00b0ff',
              strokeColor:'#80d8ff'
            });
    },
    //画图完成后，获取标记的矩形多边形。
    overlayComplete(e) {
        //让多边形一下的鼠标事件不在地图上显示
        this.overlays.push(e.obj);
        if(e.obj.getPath().length<3){
        this.overlays.pop().setMap(null);
        }
      // this.overlays.push(e.obj);
      console.log("覆盖物已完成:", e.obj);
      console.log(e.obj);
      const path = e.obj.getPath();
      console.log("path",path);
      console.log("多边形的点数量",path.length);
      // if(e.obj.getPath.length<4){
      //   //让多边形一下的鼠标事件不在地图上显示
      //   return
      // }
      if(e.obj.getPath().length>2){
      let temporypoint=[];
      this.lastTextNumber=0;
      path.forEach((point, index) => {
        if (index < path.length) { // 根据多边形点数画
          this.points[`point${String.fromCharCode(65 + index)}`] = { // A, B, C, D
            lat: point.lat,
            lng: point.lng
          };
          temporypoint.push([point.lat,point.lng]);//通过插入坐标，在循环完成后 获取零时矩形；
          const label = new AMap.Text(`点 ${this.base+index + 1}`, { position: point }); // 标注内容为 "点 1", "点 2", 等等
          var text = new AMap.Text({
            text:`点 ${this.base+index + 1}`,
            anchor:'center', // 设置文本标记锚点
            draggable:true,
            cursor:'pointer',
            angle:0,
            style:{
              'padding': '.5rem 1rem', // 调整内边距
              'margin-bottom': '0.5rem', // 调整下边距
              'border-radius': '.25rem',
              'background-color': 'transparent',
              'width': '3.5rem',// 减小宽度
              'border-width': 0,
              'box-shadow': '0 2px 6px 0 rgba(114, 124, 245, .5)',
              'text-align': 'center',
              'font-size': '16px', // 减小字体大小
              'color': 'blue'
            },
            position: point
        });
        text.setMap(this.map);
        this.textLabels.push(text); // 存储标签
        this.lastTextNumber++;//在回撤时，需要用来确定需要回撤标签的数量
        // this.textLabels.push(text); // 存储标签
        }    
      });
      this.allPoints.push(temporypoint);
      console.log(this.allPoints);
      this.base+=path.length;
      if(this.active===3){
        this.intersection();
      }
    }
      console.log(this.base+"base");
      if(this.active===2){
        this.isDrawing=false;
        this.toggleDrawing();  
      }
      if(this.active===3){
        //由于在隐藏标签后可能会画新的标签。但是会有标签，故再次进行验证
        //切换标签状态
        console.log("input")
        // this.textLabels.setMap(this.labelVisible ? this.map : null);
        this.textLabels.forEach(label => {
              label.setMap(this.labelVisible ? this.map : null); // 移除标签
          });
          this.textCenterLabels.forEach(label => {
              label.setMap(this.labelVisible ? this.map : null); // 移除标签
          });      
            }
      if(this.active===3&&this.stepBool){

      this.addExtenChild=true;//为true，在下一步，需要将线段重置一下
      }
      // if(this.active===3){
      //   this.intersection();
      // }
    },
    handleRightClick(e) {
      if (confirm(`Coordinates: ${e.point.lng}, ${e.point.lat}`)) {
        const shapeDiv = this.$el.querySelector("#shape");
        shapeDiv.innerHTML += `<br/>(${e.point.lng}, ${e.point.lat})`;
      }
    },
    handleCascaderChange(value) {
      // 处理级联选择器的变化
      console.log("当前选择的值:", value);
      if(value=="A - A1"){//吸附
        this.choicevalue=0;
      }else if(value="A - A2"){//不吸
        this.choicevalue=1;
      }else if("B"){
        this.choicevalue=2;
      }
      this.cascaderValue=value;
      console.log(this.cascaderValue);
    },
    confirmSelection() {
      this.choicevalue=2;
      if(this.choicevalue===2){
        this.drawingManager.polyline({
          strokeColor: '#FF0000', // 线条颜色
          strokeWeight: 6, // 线条宽度
          strokeOpacity: 0.8, // 线条透明度
          lineJoin: 'round', // 线条连接点样式
          lineCap: 'round' // 线条端点样式
        })
      }else{
      //将多边形覆盖重置
      this.drawingManager.polygon({
              fillColor:'#00b0ff',
              strokeColor:'#80d8ff'
            });
          }
      // 输出选择的类型
      console.log("您选择的类型:", this.selectedValues.join(' - '));
        // 隐藏级联选择器
        this.showCascader = false;
        this.showCascaderButton=true;
        this.styleOptions.strokeColor = "blue"; // 修改线条颜色
        this.styleOptions.fillColor = "lightblue"; // 修改填充颜色
        this.styleOptions.strokeWeight = 5; // 修改线条宽度
        // this.drawingManager.setPolygonOptions(this.styleOptions);
        // 可以在这里添加其他逻辑，例如更新状态或发送数据
        this.drawingManager.on();
        //this.drawingManager.setDrawingMode(AMap_DRAWING_POLYGON);
        console.log(this.drawingManager);
        console.log("子区域",this.allPoints); 
        //this.circle();
        console.log("将矩形成功进行输入")
    },
    cleanData(data) {
        return data.map(item => {
            // 创建一个新的数组，去掉 __ob__ 属性
            return item.map(point => {
                return point; // 直接返回坐标数组
            });
        });
    },
    withoutTouch(){
            this.allPoints.forEach((polygon, polygonIndex) => {
            polygon.forEach((coord, index) => {
              var text = new AMap.Text({
                  text:`点 ${this.base+index + 1}`,
                  anchor:'center', // 设置文本标记锚点
                  draggable:true,
                  cursor:'pointer',
                  angle:0,
                  style:{
                    'padding': '.5rem 1rem', // 调整内边距
                    'margin-bottom': '0.5rem', // 调整下边距
                    'border-radius': '.25rem',
                    'background-color': 'transparent',
                   'width': '3.5rem',// 减小宽度
                    'border-width': 0,
                    'box-shadow': '0 2px 6px 0 rgba(114, 124, 245, .5)',
                    'text-align': 'center',
                    'font-size': '16px', // 减小字体大小
                    'color': 'blue'
                  },
                  position: new AMap.Point(coord[1], coord[0]) 
              });
              text.setMap(this.map);
              this.textLabels.push(text); // 存储标签
              this.labeledPoints.add(`${coord[1]},${coord[0]}`); // 记录该点已经添加标签
              this.pointsReback.push({id: index + 1, coord: [coord[1], coord[0]], polygonId: polygonIndex + 1});
            });
          });

          this.base = this.allPoints.reduce((total, polygon) => total + polygon.length, 0); 
    
    },
    async intersection(){//验证最后画的子区域与边界是否相交
        //await new Promise(resolve => setTimeout(resolve, 8000));//解决函数异步问题  
        const params=this.allPoints;
        console.log("params childeran",params);
         const response = await fetch('https://gdp-api.shunshun.guoch.xyz/api/actionUnionFirstAndLast', {
          // const response = await fetch('http://127.0.0.1:6840/api/actionUnionFirstAndLast', {
            // const response = await fetch('http://172.31.32.4:6840/api/actionUnionFirstAndLast', {

          
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
          });
          console.log(JSON.stringify(params))
        //   this.aisle= params.map(polygon => {
        //     return polygon.map(point => {
        //         // 交换经度 (longitude) 和纬度 (latitude)
        //         return [point[1], point[0]];
        //     });
        // });;
          const data  = await response.json();
          const features = data.features;
          console.log(data)
          console.log("判断交集",features)

          if(features.length>0){
            console.log("有交集")
            //有交集
          }else{
            console.log("判断有无交集",features)
            this.rollback();
            this.$message({
              type: 'info',
              message: '请勿在区域边界外画子区域'
            });   
          }
    },
    async circle(){
        //await new Promise(resolve => setTimeout(resolve, 8000));//解决函数异步问题
        const params=this.allPoints;
        console.log("params childeran",params);
         const response = await fetch('https://gdp-api.shunshun.guoch.xyz/api/action', {
          // const response = await fetch('http://127.0.0.1:6840/api/action', {
            // const response = await fetch('http://172.31.32.4:6840/api/action', {

          
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
          });
          console.log(JSON.stringify(params))
        //   this.aisle= params.map(polygon => {
        //     return polygon.map(point => {
        //         // 交换经度 (longitude) 和纬度 (latitude)
        //         return [point[1], point[0]];
        //     });
        // });;
          const data = await response.json();
          this.aisle=data;
          this.showPolygon(data);
          console.log(data);
          console.log(data.type)
          console.log("返回结果"+data);
    },
    drawBoundary(){
      this.reset();
      const polygonCoordsfirst = this.allPoints[0].map((coord,index) => {
        var text = new AMap.Text({
            text:`点 ${this.base+index + 1}`,
            anchor:'center', // 设置文本标记锚点
            draggable:true,
            cursor:'pointer',
            angle:0,
            style:{
              'padding': '.5rem 1rem', // 调整内边距
                    'margin-bottom': '0.5rem', // 调整下边距
                    'border-radius': '.25rem',
                    'background-color': 'transparent',
                   'width': '3.5rem',// 减小宽度
                    'border-width': 0,
                    'box-shadow': '0 2px 6px 0 rgba(114, 124, 245, .5)',
                    'text-align': 'center',
                    'font-size': '16px', // 减小字体大小
                    'color': 'blue'
            },
            position: new AMap.LngLat(coord[1], coord[0])
        });
        text.setMap(this.map);
        this.textLabels.push(text); // 存储标签
        this.labeledPoints.add(`${coord[1]},${coord[0]}`); // 记录该点已经添加标签
        this.pointsReback.push({id:index+1,coord:[coord[1],coord[0]]})
        return new AMap.LngLat(coord[1], coord[0]); // 注意：AMap.Point 的参数是 (经度, 纬度)
      });
      this.base=this.allPoints[0].length;
      console.log("this.base"+this.base);
      const polygonfirst = new AMap.Polygon({
            path: polygonCoordsfirst, // 转换为 LngLat 对象
            fillColor:'#00b0ff',
              strokeColor:'#80d8ff',     // 填充颜色
            fillOpacity: 0.5,       // 填充透明度
            strokeWeight: 2         // 边框宽度
        });
          //重新填充
          polygonfirst.setMap(this.map);
          this.overlays.push(polygonfirst);
          this.boundary.push(this.allPoints[0]);//给边界赋值
    },
    rolldrawBoundary(){
      this.reset();
      const polygonCoordsfirst = this.allPoints[0].map((coord,index) => {
        var text = new AMap.Text({
            text:`点 ${this.base+index + 1}`,
            anchor:'center', // 设置文本标记锚点
            draggable:true,
            cursor:'pointer',
            angle:0,
            style:{
              'padding': '.5rem 1rem', // 调整内边距
                    'margin-bottom': '0.5rem', // 调整下边距
                    'border-radius': '.25rem',
                    'background-color': 'transparent',
                   'width': '3.5rem',// 减小宽度
                    'border-width': 0,
                    'box-shadow': '0 2px 6px 0 rgba(114, 124, 245, .5)',
                    'text-align': 'center',
                    'font-size': '16px', // 减小字体大小
                    'color': 'blue'
            },
            position: new AMap.LngLat(coord[1], coord[0])
        });
        text.setMap(this.map);
        this.textLabels.push(text); // 存储标签
        this.labeledPoints.add(`${coord[1]},${coord[0]}`); // 记录该点已经添加标签
        this.pointsReback.push({id:index+1,coord:[coord[1],coord[0]]})
        return new AMap.LngLat(coord[1], coord[0]); // 注意：AMap.Point 的参数是 (经度, 纬度)
      });
      this.base=this.allPoints[0].length;
      console.log("this.base"+this.base);
      const polygonfirst = new AMap.Polygon({
            path: polygonCoordsfirst, // 转换为 LngLat 对象
            fillColor:'#00b0ff',
            strokeColor:'#80d8ff',
            fillOpacity: 0.5,       // 填充透明度
            strokeWeight: 2         // 边框宽度
        });
          //重新填充
          polygonfirst.setMap(this.map);
          this.overlays.push(polygonfirst);
          this.boundary.push(this.allPoints[0]);//给边界赋值
    },
    drawBoundarySecond(){
      this.reset(); // 重置状态

      // 遍历 allPoints 数组
      this.allPoints.forEach((points, outerIndex) => {
        const polygonCoords = points.map((coord, index) => {
          const text = new AMap.Text({
            text: `点 ${this.base + index + 1}`, // 文本内容
            anchor: 'center', // 设置文本标记锚点
            draggable: true,
            cursor: 'pointer',
            angle: 0,
            style: {
              'padding': '.5rem 1rem', // 调整内边距
              'margin-bottom': '0.5rem', // 调整下边距
              'border-radius': '.25rem',
              'background-color': 'transparent',
              'width': '3.5rem', // 减小宽度
              'border-width': 0,
              'box-shadow': '0 2px 6px 0 rgba(114, 124, 245, .5)',
              'text-align': 'center',
              'font-size': '16px', // 减小字体大小
              'color': 'blue'
            },
            position: new AMap.LngLat(coord[1], coord[0]) // 使用经纬度
          });

          text.setMap(this.map); // 将文本标记添加到地图
          this.textLabels.push(text); // 存储标签
          this.labeledPoints.add(`${coord[1]},${coord[0]}`); // 记录该点已经添加标签
          this.pointsReback.push({ id: index + 1, coord: [coord[1], coord[0]] }); // 存储返回的点

          return new AMap.LngLat(coord[1], coord[0]); // 转换为 LngLat 对象
        });

        // 更新 base 值
        this.base += points.length;

        console.log("this.base: " + this.base);

        const polygon = new AMap.Polygon({
          path: polygonCoords, // 多边形路径
          fillColor:'#00b0ff',
          strokeColor:'#80d8ff',// 填充颜色
          fillOpacity: 0.5, // 填充透明度
          strokeWeight: 2 // 边框宽度
        });

        // 将多边形添加到地图
        polygon.setMap(this.map);
        this.overlays.push(polygon); // 存储覆盖物
        this.boundary.push(points); // 给边界赋值
      });
    },
    rolldrawBoundarySecond(){
      this.reset(); // 重置状态

      // 遍历 allPoints 数组
      this.allPoints.forEach((points, outerIndex) => {
        const polygonCoords = points.map((coord, index) => {
          const text = new AMap.Text({
            text: `点 ${this.base + index + 1}`, // 文本内容
            anchor: 'center', // 设置文本标记锚点
            draggable: true,
            cursor: 'pointer',
            angle: 0,
            style: {
              'padding': '.5rem 1rem', // 调整内边距
              'margin-bottom': '0.5rem', // 调整下边距
              'border-radius': '.25rem',
              'background-color': 'transparent',
              'width': '3.5rem', // 减小宽度
              'border-width': 0,
              'box-shadow': '0 2px 6px 0 rgba(114, 124, 245, .5)',
              'text-align': 'center',
              'font-size': '16px', // 减小字体大小
              'color': 'blue'
            },
            position: new AMap.LngLat(coord[1], coord[0]) // 使用经纬度
          });

          text.setMap(this.map); // 将文本标记添加到地图
          this.textLabels.push(text); // 存储标签
          this.labeledPoints.add(`${coord[1]},${coord[0]}`); // 记录该点已经添加标签
          this.pointsReback.push({ id: index + 1, coord: [coord[1], coord[0]] }); // 存储返回的点

          return new AMap.LngLat(coord[1], coord[0]); // 转换为 LngLat 对象
        });

        // 更新 base 值
        this.base += points.length;

        console.log("this.base: " + this.base);
        let polygon ;
        if(outerIndex!==0){
          polygon = new AMap.Polygon({
            path: polygonCoords, // 多边形路径
            fillColor:'#00b0ff',
            strokeColor:'#80d8ff',
            fillOpacity: 0.5, // 填充透明度
            strokeWeight: 2 // 边框宽度
          });
        }else{
          polygon = new AMap.Polygon({
            path: polygonCoords, // 多边形路径
            fillColor:'#00b0ff',
            strokeColor:'#80d8ff',
            fillOpacity: 0.5, // 填充透明度
            strokeWeight: 2 // 边框宽度
          });
        }
        // 将多边形添加到地图
        polygon.setMap(this.map);
        this.overlays.push(polygon); // 存储覆盖物
        this.boundary.push(points); // 给边界赋值
      });
    },
    showPolygon(data){
      this.rolldrawBoundary()
      // this.reset();
      // const polygonCoordsfirst = this.allPoints[0].map((coord,index) => {
      //   var text = new AMap.Text({
      //       text:`点 ${this.base+index + 1}`,
      //       anchor:'center', // 设置文本标记锚点
      //       draggable:true,
      //       cursor:'pointer',
      //       angle:0,
      //       style:{
      //         'padding': '.5rem 1rem', // 调整内边距
      //               'margin-bottom': '0.5rem', // 调整下边距
      //               'border-radius': '.25rem',
      //               'background-color': 'transparent',
      //              'width': '3.5rem',// 减小宽度
      //               'border-width': 0,
      //               'box-shadow': '0 2px 6px 0 rgba(114, 124, 245, .5)',
      //               'text-align': 'center',
      //               'font-size': '16px', // 减小字体大小
      //               'color': 'blue'
      //       },
      //       position: new AMap.LngLat(coord[1], coord[0])
      //   });
      //   text.setMap(this.map);
      //   this.textLabels.push(text); // 存储标签
      //   this.labeledPoints.add(`${coord[1]},${coord[0]}`); // 记录该点已经添加标签
      //   this.pointsReback.push({id:index+1,coord:[coord[1],coord[0]]})
      //   return new AMap.LngLat(coord[1], coord[0]); // 注意：AMap.Point 的参数是 (经度, 纬度)
      // });
      // this.base=this.allPoints[0].length;
      // console.log("this.base"+this.base);
      // const polygonfirst = new AMap.Polygon({
      //       path: polygonCoordsfirst, // 转换为 LngLat 对象
      //       strokeColor: "#06B6D4",   // 边框颜色
      //       fillColor: "#5EEAD4",      // 填充颜色
      //       fillOpacity: 0.5,       // 填充透明度
      //       strokeWeight: 2         // 边框宽度
      //   });
      //     //重新填充
      //     polygonfirst.setMap(this.map);
      //     this.overlays.push(polygonfirst);
      //     this.boundary.push(this.allPoints[0]);//给边界赋值
      let indexs=0;//后面做了一个判断，这个用来计数第几个
      let polygonCoords;
      if(data.type==="Polygon"){
        console.log("进入Polygon分支")
          polygonCoords = data.coordinates[0].map((coord,index) => {
          const coordKey = `${coord[1]},${coord[0]}`; // 创建唯一的坐标键
          this.polygonData.push([coord[0],coord[1]]);
          if (!this.labeledPoints.has(coordKey)) { //该点不存在标签才加入第几点
            indexs++;
            var text = new AMap.Text({
              text:`点 ${this.base+indexs}`,
              anchor:'center', // 设置文本标记锚点
              draggable:true,
              cursor:'pointer',
              angle:0,
              style:{
                'padding': '.5rem 1rem', // 调整内边距
                    'margin-bottom': '0.5rem', // 调整下边距
                    'border-radius': '.25rem',
                    'background-color': 'transparent',
                   'width': '3.5rem',// 减小宽度
                    'border-width': 0,
                    'box-shadow': '0 2px 6px 0 rgba(114, 124, 245, .5)',
                    'text-align': 'center',
                    'font-size': '16px', // 减小字体大小
                    'color': 'blue'
              },
              position: new AMap.LngLat(coord[1], coord[0])
          });
          text.setMap(this.map);
          this.textLabels.push(text); // 存储标签
          this.pointsReback.push({id:this.base+indexs,coord:[coord[1],coord[0]]})
          }  
          this.labeledPoints.add(`${coord[1]},${coord[0]}`); // 记录该点已经添加标签
          return new AMap.LngLat(coord[1], coord[0]); // 注意：AMap.Point 的参数是 (经度, 纬度)
        });
      }else if(data.type==="MultiPolygon"){
        console.log("进入MultiPolygon分支")
      data.coordinates.forEach((polygon, polygonIndex) => {
          let polygonCoords = []; // 初始化每个多边形的坐标数组
          polygon.forEach((coords, coordsIndex) => {
              coords.forEach((point) => {
                  const coordKey = `${point[1]},${point[0]}`; // 创建唯一的坐标键
                  this.polygonData.push([point[0], point[1]]);
                  console.log(point[0] + " sadffdas");
                  if (!this.labeledPoints.has(coordKey)) { // 检查该点是否已存在标签
                      indexs++;
                      var text = new AMap.Text({
                        text:`点 ${this.base+indexs}`,
                        anchor:'center', // 设置文本标记锚点
                        draggable:true,
                        cursor:'pointer',
                        angle:0,
                        style:{
                          'padding': '.5rem 1rem', // 调整内边距
                          'margin-bottom': '0.5rem', // 调整下边距
                          'border-radius': '.25rem',
                          'background-color': 'transparent',
                          'width': '3.5rem',// 减小宽度
                          'border-width': 0,
                          'box-shadow': '0 2px 6px 0 rgba(114, 124, 245, .5)',
                          'text-align': 'center',
                          'font-size': '16px', // 减小字体大小
                          'color': 'blue'
                        },
                        position: new AMap.LngLat(point[1], point[0])
                    });
                    text.setMap(this.map);
                    this.textLabels.push(text); // 存储标签
                      this.pointsReback.push({ id: this.base + indexs, coord: [point[1], point[0]] });
                      this.labeledPoints.add(coordKey); // 记录该点已经添加标签
                  }
                  // 将当前点加入 polygonCoords
                  polygonCoords.push(new AMap.LngLat(point[1], point[0])); // 注意：AMap.Point 参数为 (经度, 纬度)
              });
          }); 
          console.log(polygonCoords )
          console.log(" sadffadssafd"); // 输出 polygonCoords
          const polygonone = new AMap.Polygon({
            path: polygonCoords, // 转换为 LngLat 对象
            fillColor:'#00b0ff',
            strokeColor:'#80d8ff',     // 填充颜色
            fillOpacity: 0.5,       // 填充透明度
            strokeWeight: 2         // 边框宽度
        });
          //重新填充
          polygonone.setMap(this.map);
          this.overlays.push(polygonone);
          console.log(polygonone)
          console.log("one");
          console.log(polygon); // 这里可能需要输出 polygonCoords 而不是 polygon
      });
      }else if(data.type==="FeatureCollection"){
        console.log("进入FeatureCollection分支");
        data.features.forEach(feature => {
          if (feature.geometry.type === "Polygon") {
              feature.geometry.coordinates.forEach(polygon => {
                  let polygonCoords = []; // 初始化每个多边形的坐标数组
                  polygon.forEach(point => {
                      const coordKey = `${point[1]},${point[0]}`; // 创建唯一的坐标键
                      this.polygonData.push([point[0], point[1]]);
                      console.log(point[0] + " sadffdas");
                      if (!this.labeledPoints.has(coordKey)) { // 检查该点是否已存在标签
                          indexs++;
                          var text = new AMap.Text({
                              text:`点 ${this.base+indexs}`,
                              anchor:'center', // 设置文本标记锚点
                              draggable:true,
                              cursor:'pointer',
                              angle:0,
                              style:{
                                'padding': '.5rem 1rem', // 调整内边距
                                'margin-bottom': '0.5rem', // 调整下边距
                                'border-radius': '.25rem',
                                'background-color': 'transparent',
                                'width': '3.5rem',// 减小宽度
                                'border-width': 0,
                                'box-shadow': '0 2px 6px 0 rgba(114, 124, 245, .5)',
                                'text-align': 'center',
                                'font-size': '16px', // 减小字体大小
                                'color': 'blue'
                              },
                              position:new AMap.LngLat(point[1], point[0])
                          });
                          text.setMap(this.map);
                          this.textLabels.push(text);
                          this.pointsReback.push({ id: this.base + indexs, coord: [point[1], point[0]] });
                          this.labeledPoints.add(coordKey); // 记录该点已经添加标签
                      }
                      // 将当前点加入 polygonCoords
                      polygonCoords.push(new AMap.LngLat(point[1], point[0])); // 注意：AMap.Point 参数为 (经度, 纬度)
                  });
                  console.log(polygonCoords);
                  console.log(" sadffadssafd"); // 输出 polygonCoords
                  const polygonOne = new AMap.Polygon({
                      path: polygonCoords, // 初始路径为空
                      fillColor:'#00b0ff',
                      strokeColor:'#80d8ff',     // 填充颜色
                      fillOpacity: 0.5,       // 填充透明度
                      strokeWeight: 2         // 边框宽度
                  });
                  // 将多边形添加到地图
                  polygonOne.setMap(this.map);
                  this.overlays.push(polygonOne);
                  console.log("one");
                  console.log(polygon); // 这里可能需要输出 polygonCoords 而不是 polygon
              });
          }
      });
      }
      this.base+=indexs;
      console.log("2this.base"+this.base);
      this.drawingManager.polygon({
        fillColor:'#00b0ff',
        strokeColor:'#80d8ff',
              //同Polygon的Option设置
            });
      // 输出调试信息
      console.log(polygonCoords);
      console.log("one");
     // console.log(polygon);
      console.log(this.pointsReback);
      const polygon = new AMap.Polygon({
            path: polygonCoords, // 初始路径为空
            fillColor:'#00b0ff',
              strokeColor:'#80d8ff',    // 填充颜色
            fillOpacity: 0.5,       // 填充透明度
            strokeWeight: 2         // 边框宽度
        });
        // 将多边形添加到地图
        polygon.setMap(this.map);
        this.overlays.push(polygon);

        //回退上一步后下来画线段的，前面有清楚操作，放在这里了
        console.log("entrancesAndExits",this.entrancesAndExits);
        console.log(this.drawLineBool);
                if(this.addExtenChild){//线段需要重新选择
                  console.log("运行了线段重置操作")
                  this.entrancesAndExits=[];
                  this.selectList=[];
                  this.addSelect();//可行
                }
                if(this.active===3){
                  this.entrancesAndExits.forEach((line=>{
                    line.setMap(null);
                  }))
                }else if(this.active===4){
                  this.entrancesAndExits.forEach((line=>{
                line.setMap(this.map);
              }))
                }
                if(this.drawLineBool){
                  this.drawLineBool=false;
                }
              
                // if(this.stepBool){
                //   this.entrancesAndExits.forEach((line=>{
                //     // line.setMap(null);
                //     line.setMap(this.drawLineBool?this.map:null);
                //   }))
                // }
    },
    convertToGeoJSON() {
          // 将点对象转换为 GeoJSON 格式
          const coordinates = Object.values(this.points).map(point => [point.lng, point.lat]);
          return {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: {
                  type: "Polygon",
                  coordinates: [coordinates]  // GeoJSON 要求坐标数组是嵌套的
                },
                properties: {}
              }
            ]
          };
    },
    safeStringify(obj) {
      const seen = new WeakSet();
      return JSON.stringify(obj, (key, value) => {
        if (typeof value === "object" && value !== null) {
          if (seen.has(value)) {
            return; // 遇到循环引用，返回 undefined
          }
          seen.add(value);
        }
        return value;
      });
    },
    exportData(){
      // const geojson = this.convertToGeoJSON();
      // const blob = new Blob([JSON.stringify(geojson, null, 2)], {
      //   type: "application/json"
      // });
      // const url = URL.createObjectURL(blob);
      // const a = document.createElement("a");
      // a.href = url;
      // a.download = "data.geojson";  // 下载的文件名
      // a.click();
      // URL.revokeObjectURL(url);  // 释放 URL 对象
      console.log("输出待导出的数据");
  console.log(this.boundary);
  console.log(this.aisle);
  console.log(this.exit);
  console.log(this.subarea);

  const boundaryData = {
    type: "FeatureCollection",
    features: [{
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [this.boundary[0].map(coord => [coord[1], coord[0]])]
      },
      properties: {}
    }]
  };

  // 输出第一个文件数据
  console.log("Polygon Data:", this.safeStringify(boundaryData));

  const remainingPolygons = this.allPoints.slice(1);  // 获取其它多边形
  const swappedCoordinates = this.aisle.coordinates.map(polygon => 
    polygon.map(coord => [coord[1], coord[0]]) // 对调每个坐标点
);
  const hallAisleData = {
    type: "FeatureCollection",
    features: [{
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: swappedCoordinates // 修正为 this.aisle,经纬度进行对调
      },
      properties: {}
    }]
  };

  console.log("Subtracted Polygon Data (not fully implemented):", this.safeStringify(hallAisleData));


  const entranceExitData = {
        type: "FeatureCollection",
        features: this.exit.map(line => ({
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: line
          },
          properties: {
            description: "由两个点构成的线段"
          }
        }))
      };
  console.log("Selected Data:", this.safeStringify(entranceExitData));

  const subareaData = {
    type: "FeatureCollection",
    features: this.subarea.map(area => ({
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [area.map(coord => [coord[1], coord[0]])] // 转换为 [经度, 纬度]
        },
        properties: {}
      }))
  };

  console.log("Overlays Data:", this.safeStringify(subareaData));

  const downloadFile = (data, filename) => {
    const blob = new Blob([this.safeStringify(data)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  // 导出文件
  downloadFile(boundaryData, `${this.areaName}_hall_boundary.geojson`);
  downloadFile(hallAisleData, `${this.areaName}_hall_aisle.geojson`);
  downloadFile(entranceExitData, `${this.areaName}_hall_entrance_exit.geojson`);
  downloadFile(subareaData, `${this.areaName}_hall_subarea.geojson`);
    },
    reset() {
      this.map.remove(this.overlays)
      this.intersectingLine.forEach(line=>{
        line.setMap(null);
      })

      this.overlays.forEach(polygon=>{
          polygon.setMap(null);
      })
      this.textLabels.forEach(label => {
            label.setMap(null); // 移除标签
        });
        this.textCenterLabels.forEach(label => {
            label.setMap(null); // 移除标签
        });
      this.drawLine.forEach((line=>{
          line.setMap(null);
      }))
      this.entrancesAndExitsCopy=this.entrancesAndExits;
      this.entrancesAndExits.forEach((line=>{
          // line.setMap(null);
          line.setMap(this.drawLineBool ? this.map : null);
        }))
        this.textLabels = []; // 清空数组
       this.base=0;//重新开始记数
      this.labeledPoints.clear(); // 清空 labeledPoints   
      this.pointsRebackcopy = this.pointsReback; //在出入口过滤时使用
       this.pointsReback=[];//清空用来选择出入口 的数据
       this.polygonData=[];
      },
    setPointNumber(){
      const path = e.obj.getPath();
      path.forEach((point, index) => {
        if (index < 4) { // 确保只更新前四个点
          this.points[`point${String.fromCharCode(65 + index)}`] = { // A, B, C, D
            lat: point.lat,
            lng: point.lng
          };
          this.polygonPoints.push(new AMap.Point(this.points[index].lat, this.points[index].lng)); 

        }
      });
    },
    getPoint() {
      document.getElementById("resultShape").innerHTML = '';
      for (let overlay of this.overlays) {
        const path = overlay.getPath();
        document.getElementById("resultShape").innerHTML += `${path.length}边形:<br/>`;
        path.forEach((point, index) => {
          document.getElementById("resultShape").innerHTML += `${index + 1}个点:(${point.lng},${point.lat});<br/>`;
        });
      }
    },
    editing(state) {
      for (let overlay of this.overlays) {
        state === 'enable' ? overlay.enableEditing() : overlay.disableEditing();
      }
    },
    showOverlayCount() {
      alert(this.overlays.length);
    },
    setAreaName(){
      this.$prompt('请输入区域名称', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputValue: this.areaName,
          inputPattern: '',
          inputErrorMessage: '区域不正确'
        }).then(({ value }) => {
          // this.$message({
          //   type: 'success',
          //   message: '区域是: ' + value
          // });
          this.areaName=value;
          this.$message({
            type: 'info',
            message: '请在地图上画边界，双击左键结束画图'
          }); 
          this.isDrawing=true;
          this.toggleDrawing();  
          this.setBoundary(); // 确保这是一个方法调用
          this.active=2;
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          });       
        });   
    },
    setBoundary(){
        console.log("map",this.map);
        this.draw('polygon');
    },
    addSelect() {
      this.selectList.push({ selected: null }); // 添加新的选择器
    },
    removeSelect(index) {
    // // 删除指定索引的出入口
    // const item = this.selectList[index];

    // // 如果有绘制的线段，则从地图上移除
    // if (item.polyline) {
    //   item.polyline.setMap(null); // 从地图上移除线段
    // }
    console.log("当前选择:", this.selectList);
  const item = this.selectList[index];

  if (item.selected && item.selected2) {
    const startPoint = item.selected;
    const endPoint = item.selected2;
    this.entrancesAndExits.pop().setMap(null);

    // this.selectList.splice(index, 1); // 删除出入口
  }
  this.selectList.splice(index, 1); // 删除出入口
    },
    handleCancel(){
      //取消分区
      this.subdivideRegion=false;

    },
    handleBlur(){
      console.log(this.row+"和"+this.column)
     if (this.row !== null && this.row !== '' && this.column !== null && this.column !== '') {
        // this.subdivideRegion=false;
        console.log('两个输入框都已填写，触发事件');
        // 这里可以添加其他逻辑
        this.drawline();
     }
    },
    handleSelectionChange(){
      console.log(this.selectedLocation+"dsadfasdffdsadfsafdsa")
    },
    drawline(){//子区域切分，画矩形
      this.row;
      this.column;
      console.log("画当前区域");
      console.log(this.selectedLocation);
      const num=this.selectedLocation.replace(/\D/g, '')-1;
      console.log(num);
      // const now=[this.ret.coordinates[num]];
      const now=[this.ret.features[num].geometry.coordinates];
      console.log("子区划分now，获取的多边形",now);
      this.child(now);
    },
    async child(polygons){
      //await new Promise(resolve => setTimeout(resolve, 5000));//解决函数异步问题
      const params=  {
        coordinates: polygons,
        horizontal_segments: this.row,
        vertical_segments: this.column
      };;
      console.log("激活python函数画子区域参数");
      console.log(params);
       const response = await fetch('https://gdp-api.shunshun.guoch.xyz/api/children', {
        // const response = await fetch('http://127.0.0.1:6840/api/children', {
          // const response = await fetch('http://172.31.32.4:6840/api/children', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(params)
        });
        console.log(JSON.stringify(params))
        const data = await response.json();
        console.log("子区域划分结果"+data);
        this.retTwo=data;
        console.log(data.rectangles)
        this.lastChildpolygonNumber=0;
        data.rectangles.forEach(polygon=>{
          this.subarea.push(polygon);//用来存储py返回的切割后的摊位多边形
          console.log(polygon);
          this.lastChildpolygonNumber++;
        })
        //this.subare=data.rectangles;
        console.log("摊位",this.subarea);
                 // 绘制线段
        this.lastLineNumber=0;
        data.lines.forEach(line =>{
            var points = line.map(function(coord) {
              console.log(coord)
                return new AMap.LngLat(coord[1], coord[0]);
            });
            const polyline = new AMap.Polyline({
                path: points, // 设置线段的路径
                strokeColor: "black", // 线条颜色
                strokeWeight: 4,     // 线条宽度
                strokeOpacity: 1      // 线条透明度
            });
            // 将线段添加到地图
            polyline.setMap(this.map);
            this.intersectingLine.push(polyline);
            this.intersectingLinePrepared.push(polyline);
            this.lastLineNumber++;
        });
    },
    drawPolyline(){//上一步后返回画摊位的
       // 重新显示已准备的线段
       this.intersectingLinePrepared.forEach(line => {
        line.setMap(this.map); // 重新添加到地图
        this.intersectingLine.push(line); // 添加到当前线段数组
      });
      console.log("回复后的子区域",this.intersectingLine);
      this.hidePoint();  

    },
    filteredPointsReback(index, isSecondSelect) {
      //return this.pointsReback;
      console.log("pointsReback",this.pointsReback);
      console.log("this.selectList",this.selectList);
      const selectedValues = this.selectList.flatMap(item => [item.selected, item.selected2]).filter(val => val !== null); // 过滤掉 null 值
      console.log(selectedValues)
      if(this.steepBool){
          return this.pointsRebackcopy.filter(option => {
          const isSelected = selectedValues.includes(option.coord);
          // 过滤已选择的值
          return !isSelected || (isSecondSelect && option.coord === this.selectList[index].selected2);
        });
      }
      return this.pointsReback.filter(option => {
        const isSelected = selectedValues.includes(option.coord);
        // 过滤已选择的值
        return !isSelected || (isSecondSelect && option.coord === this.selectList[index].selected2);
      });
    },
    updateSelections(index) {
      console.log("当前选择:", this.selectList);
  const item = this.selectList[index];

  if (item.selected && item.selected2) {
    const startPoint = item.selected;
    const endPoint = item.selected2;

    // 如果已经存在 polyline，则先从地图上移除
    if (this.entrancesAndExits[index]) {
      this.entrancesAndExits[index].setMap(null); // 从地图上移除旧的折线
    }

    // 创建并绘制新的折线
    const polyline = new AMap.Polyline({
      path: [startPoint, endPoint], // 线段的坐标点
      strokeColor: "#0A0A0A", // 线段颜色
      strokeWeight: 5, // 线段宽度
    });

    this.entrancesAndExits[index] = polyline; // 存储新的折线
    polyline.setMap(this.map); // 将新折线添加到地图上
  } else {
    // 如果没有选择有效的起点或终点，移除对应的折线
    if (this.entrancesAndExits[index]) {
      this.entrancesAndExits[index].setMap(null); // 从地图上移除
      this.entrancesAndExits[index] = null; // 清空引用
    }
  }
    },
    addSelect() {
      this.selectList.push({ selected: null, selected2: null });
    },
    // resetSelectList() {
    //   this.selectList = [{ selected: null, selected2: null }];
    // },
    cancelSubmit(){
      this.inandout = false;
      this.active++;
      //执行完出入口选择后直接跳入下一步
      if(this.stepBool){
            console.log("回复画子区域cancelSubmit");
            console.log(this.intersectingLinePrepared);
              // this.drawPolyline();
              this.showPolygon(this.ret);
              this.getAreaCentPoints(this.ret);
              this.drawPolyline();
              this.subdivideRegion = true; 
              this.inandout = false;
            }else{
              this.handleSubdivide(); // 确保这是一个方法调用
            }
          this.showCascaderButton=false;
    },
    submitSelections() {
      this.inandout = false;//关闭弹窗
      console.log("提交的选择:", this.selectList);
      this.selectList.forEach(line => {
        // 创建线段的坐标数组
        const lineSegment = [
          [line.selected[0], line.selected[1]], // [经度, 纬度]
          [line.selected2[0], line.selected2[1]]
        ];
        
        // 将线段推送到 exit 数组
        this.exit.push(lineSegment);
        });
      // 提交逻辑
      if(this.stepBool){
            console.log("回复画子区域submitSelections");
            console.log(this.intersectingLinePrepared);
            console.log("this.ret");
            console.log(this.ret);
              // this.drawPolyline();
              this.showPolygon(this.ret);
              this.getAreaCentPoints(this.ret);
              this.drawPolyline();
              this.subdivideRegion = true; 
              this.inandout = false;
            }else{
              this.handleSubdivide(); // 确保这是一个方法调用
            }
            this.active++;
          this.showCascaderButton=false;
    },
    handleSubdivide() {
      this.subdivideRegion = true; // 设置 subdivideRegion
        this.setArea(); // 调用其他函数
    },
    async setArea(){
      //await new Promise(resolve => setTimeout(resolve, 5000));//解决函数异步问题
      const params=this.allPoints;
      console.log(params);
      const response = await fetch('https://gdp-api.shunshun.guoch.xyz/api/actionUnion', {
        //  const response = await fetch('http://127.0.0.1:6840/api/actionUnion', {
          // const response = await fetch('http://172.31.32.4:6840/api/actionUnion', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(params)
        });
        console.log(JSON.stringify(params))
        const data = await response.json();
        this.ret=data;
        console.log(data);
        console.log(data.type);
        console.log("当前的data");
        this.reset();
        this.showPolygon(data);
        this.getAreaCentPoints(data);
    },
    showChildrenPolygon(data){
      this.reset();
      const polygonCoordsfirst = this.allPoints[0].map((coord,index) => {
        const label = new AMap.Text(`点 ${index + 1}`, {  position: new AMap.Point(coord[1], coord[0]) }); // 标注内容为 "点 1", "点 2", 等等
        label.setStyle({
          color: "#fff",
          backgroundColor: "blue",
          border: "none",
          padding: "5px",
          borderRadius: "5px",
          fontSize: "14px"
        });
        this.map.addOverlay(label); 
        this.labeledPoints.add(`${coord[1]},${coord[0]}`); // 记录该点已经添加标签
        this.pointsReback.push({id:index+1,coord:[coord[1],coord[0]]})
        return new AMap.Point(coord[1], coord[0]); // 注意：AMap.Point 的参数是 (经度, 纬度)
      });
      this.base=this.allPoints[0].length;
      console.log("this.base"+this.base);
      const polygonfirst = new AMap.Polygon(polygonCoordsfirst,{
        fillColor:'#00b0ff',
        strokeColor:'#80d8ff',
        strokeWeight: 3,
        strokeOpacity: 0.8,
        fillOpacity: 0.6,
        strokeStyle: 'solid'
      });
      let indexs=0;//后面做了一个判断，这个用来计数第几个
      const polygonCoords = data.coordinates[0].map((coord,index) => {
        const coordKey = `${coord[1]},${coord[0]}`; // 创建唯一的坐标键
        this.polygonData.push([coord[0],coord[1]]);
        if (!this.labeledPoints.has(coordKey)) { //该点不存在标签才加入第几点
          indexs++;
          const label = new AMap.Text(`点 ${this.base+indexs}`, {  position: new AMap.Point(coord[1], coord[0]) }); // 标注内容为 "点 1", "点 2", 等等
          label.setStyle({
          color: "#fff",
          backgroundColor: "blue",
          border: "none",
          padding: "5px",
          borderRadius: "5px",
          fontSize: "14px"
        });
        this.map.addOverlay(label); 
        this.pointsReback.push({id:this.base+indexs,coord:[coord[1],coord[0]]})
        }  
        this.labeledPoints.add(`${coord[1]},${coord[0]}`); // 记录该点已经添加标签
        return new AMap.Point(coord[1], coord[0]); // 注意：AMap.Point 的参数是 (经度, 纬度)
      });
      this.base+=indexs;
      console.log("2this.base"+this.base);
      const polygon = new AMap.Polygon(polygonCoords, {
        fillColor:'#00b0ff',
        strokeColor:'#80d8ff',
        fillOpacity: 0.5,   // 填充透明度
        strokeWeight: 2      // 边框宽度
      });
      console.log(polygonCoords);
      console.log("one");
      console.log(polygon);
      console.log(this.pointsReback);
    },
    getAreaCentPoints(data) {
        console.log(data.features);
        this.rectangles = data.features.map((feature, index) => {
            let sumLat = 0;
            let sumLng = 0;
            let pointCount = 0; // 记录有效点的数量
            // 获取多边形的坐标
            const polygons = feature.geometry.coordinates;
            polygons.forEach((polygon) => {
                console.log(polygon);
                polygon.forEach((point) => {
                    sumLat += point[0]; // 纬度
                    sumLng += point[1]; // 经度
                    pointCount++; // 有效点计数
                });
            });
            // 计算中心点
            let centerPoint = [sumLat / pointCount, sumLng / pointCount];
            console.log(centerPoint + "123456879");
            return {
                name: `子区域 ${index + 1}`,
                center: centerPoint,
                polygon: polygons
            };
        });
        console.log("所有的中心点");
        console.log(this.rectangles);
        // 显示中心点
        this.rectangles.forEach((rect, index) => {
            var text = new AMap.Text({
            text:`区域 ${index + 1}`,
            anchor:'center', // 设置文本标记锚点
            draggable:true,
            cursor:'pointer',
            angle:0,
            style:{
              'padding': '.5rem 1rem', // 调整内边距
              'margin-bottom': '0.5rem', // 调整下边距
              'border-radius': '.25rem',
              'background-color': 'transparent',
              'width': '3.5rem',// 减小宽度
              'border-width': 0,
              'box-shadow': '0 2px 6px 0 rgba(114, 124, 245, .5)',
              'text-align': 'center',
              'font-size': '16px', // 减小字体大小
              'color': 'blue'
            },
            position: new AMap.LngLat(rect.center[1], rect.center[0])
        });
        text.setMap(this.map);
        this.textCenterLabels.push(text);
       // this.textLabels.push(text);
        });  
        this.hidePoint();  
    },
    calculateAverageCoordinates(coords){
      let totalLat = 0;
      let totalLng = 0;

      coords.forEach(point => {
        totalLat += point[0]; // 累加纬度
        totalLng += point[1]; // 累加经度
      });

        // 计算平均值
      const averageLat = totalLat / coords.length;
      const averageLng = totalLng / coords.length;

      // 返回平均坐标数据
      return [averageLat,averageLng];
    },
    importData(){
      if(this.boolshow===0){
        this.boolshow=1;
      }else{
      this.$message({
            type: 'info',
            message: '请导入.geojson后缀的文件'
          });
        } 
    },
    canlImport(){
      this.boolshow=0;
      this.resetData();
    },
    handleClick() {
        if (this.active === 5) {
          console.log("hidePoint()")
            this.hidePoint(); // 激活 hidePoint() 函数
        } else {
          console.log("hide()")
            this.hide(); // 调用原来的 hide() 函数
        }
    },
    hidePoint(){//隐藏子区域划分时的点标签
      //将标签临时隐藏
      //切换标签状态
      this.labelVisible = !this.labelVisible;    
      console.log("隐藏切换前hidePoint"+this.labelVisible)
      // this.textLabels.setMap(this.labelVisible ? this.map : null);
      this.textLabels.forEach(label => {
            label.setMap(this.labelVisible ? this.map : null); // 移除标签
        });    
        console.log("隐藏切换后")
    },
    hide(){
      //将标签临时隐藏
      //切换标签状态
      console.log("input")
      this.labelVisible = !this.labelVisible;    
      console.log("隐藏切换前hide")
      // this.textLabels.setMap(this.labelVisible ? this.map : null);
      this.textLabels.forEach(label => {
            label.setMap(this.labelVisible ? this.map : null); // 移除标签
        });
        this.textCenterLabels.forEach(label => {
            label.setMap(this.labelVisible ? this.map : null); // 移除标签
        });      
        console.log("隐藏切换后")
    },
    rollback(){
      //划分摊位的阶段
      console.log("回退阶段的this.active",this.active);
      if(this.active===5){
        console.log("回退摊位的")
        for(let i=0;i<this.lastLineNumber;i++){
          this.intersectingLine.pop().setMap(null);
        };
        for(let i=0;i<this.lastChildpolygonNumber;i++){
          this.subarea.pop();

        };
        
        return;

      }
      //回退上一步画的多边形
      if(this.active===2){
        this.undoDrawBoundary();
      }else{
        const lastPolygon=this.overlays.pop();
        lastPolygon.setMap(null);
        if (this.allPoints.length) {//这个数组用来存储给python传输的数据，也需要删除
          this.allPoints.pop();
        }
        for(let i=0;i<this.lastTextNumber;i++){
          this.textLabels.pop().setMap(null);
        }
        this.base-=this.lastTextNumber;
        console.log("回退");
      }
    },
    undoDrawBoundary() {
      this.reset();
      //this.draw();
      this.drawingManager.close(true); // 关闭并清除覆盖物
      this.showCascader = false;
      this.showCascaderButton = false;
      this.inandout = false;
      this.subdivideRegion = false;
      this.choicevalue = ""; // 0, 1, 2
      this.row = "";
      this.column = "";
      this.base = 0; // 记录点的基础的
      this.labeledPoints = new Set(); // 用于存储已添加标签的点
      this.areaNamBoundary = "";
      this.areaNameAisle = "";
      this.areaNameEntranceExit = "";
      this.areaNameSubarea = "";
      this.cascaderValue = "";
      this.areaCenterPoints = [];
      this.polygonPoints = [];
      this.rectangles = []; // 用于存储命名后的 area 的矩形数组，子区细分部分
      this.polygonData = [];
      //this.map = null;
      this.overlays = [];
      // this.drawingManager = null;
      this.styleOptions = {
        strokeColor: "red",
        fillColor: "red",
        strokeWeight: 3,
        strokeOpacity: 0.8,
        fillOpacity: 0.6,
        strokeStyle: 'solid'
      };
      this.ret = {};
      this.retTwo = {};
      this.selectedLocation = [];
      this.locations = [
        { value: 'location1', label: '位置 1' },
        { value: 'location2', label: '位置 2' },
        { value: 'location3', label: '位置 3' },
      ];
      this.temporary = [];
      this.allPoints = []; // data 中定义 polygons 数组
      this.pointsReback = [];
      this.pointsRebackcopy = [];
      this.selectList = [{ selected: null }]; // 初始选择器
      this.childrenoptions = [ // 可选项
        { value: 'option1', label: '1' },
        { value: 'option2', label: '2' },
        { value: 'option3', label: '3' },
      ];
      this.options = [
        {
          value: 'A',
          label: '手动圈选',
          children: [
            { value: 'A1', label: '吸附边缘' },
            { value: 'A2', label: '不吸附' }
          ]
        },
        {
          value: 'B',
          label: '自动划分',
        }
      ];
      this.textLabels = [];
      this.activeName = ''; // 默认激活的标签
      this.labelVisible = true; // 用于地图标签初始化 显示
      this.lastTextNumber = 0; // 在回撤时，需要用来确定需要回撤标签的数量
      this.lastLineNumber=0;
      this.lastChildpolygonNumber=0;
      this.intersectingLine = [];
      this.intersectingLinePrepared=[];
      this.finalStep=0;
      this.boundary = []; // 单独用来存储导出的数据。边界
      this.aisle = []; // 过道
      this.exit = []; // 出入口, 这个好弄 selectList
      this.subarea = []; // 细分区域.
      this.numstep = 0; // 用来区分进栈的步骤
      this.uploadUrl = ''; // 上传地址 (可以留空，因为我们不上传到服务器)
      this.fileList = []; // 存储选择的文件
      this.drawLine=[];
      this.isDrawing=true;
      this.entrancesAndExits=[];
    
      this.toggleDrawing();  
      this.setBoundary(); // 确保这是一个方法调用
    },
    childset(){
      if(this.choicevalue===2){
        this.autioDraw();
      }else if(this.choicevalue===1){

      }

      this.circle();
      // this.showCascaderButton=false;
    },
    autioDraw(){

    },
    previousStep(){
      this.active--;
      this.stepBool=true;
      console.log("上一步，当前active",this.active)
      switch(this.active){
        case 1: this.setAreaName(); // 确保这是一个方法调用
          this.numstep=0;
          break;
        case 2:
        this.$message({
            type: 'info',
            message: '请在地图上画边界，双击左键结束画图'
          });   
          this.rolldrawBoundary();
          break;
        case 3:
          // this.isDrawing=true;//在监听事件中画一个边界就关闭了，这里再次启用
          // this.toggleDrawing();   
          this.showCascader = true ; // 确保这是一个方法调用
          this.inandout = false;
          this.rolldrawBoundarySecond();
          //this.reset();
          this.$message({
              type: 'info',
              message: '请在地图上画对应子区域，双击左键结束画图'
            });   
            console.log("44554455",this.stepBool);
      // if(this.active===3&&this.stepBool){

      //   this.drawLineBool=true;//为true，在下一步，需要将线段重置一下
      // }
            break;
        case 4:
                //this.childset()//用来实现上一步圈选后的子区  
                this.inandout = true;
                this.showCascaderButton=false; // 确保这是一个方法调用
                this.subdivideRegion=false;
                this.showPolygon(this.aisle);
                this.addExtenChild=false;
          break;
        case 5:
        this.handleSubdivide(); // 确保这是一个方法调用
                      this.showCascaderButton=false;
          break;
        case 6:this.exportData(); // 确保这是一个方法调用
                this.subdivideRegion=false;
          break;
        case 7:this.importData(); // 确保这是一个方法调用
          break;
        // case 8:this.exportData(); // 确保这是一个方法调用
        //   break;
        case 8:this.reset(); // 确保这是一个方法调用
                      this.showCascaderButton=false;
          break;
        case 9:this.hide();
          break;
        case 10:this.rollback();
          break;
        case 11:this.rollback();
          break;
       }   
    },
    nextStep(){
      // this.isDrawing=true;
      // this.toggleDrawing();
      if(this.areaName===""&&this.active===1){
        this.setAreaName();
        return;
      }
      if(this.areaName===""&&this.active===1){
        this.setAreaName();
        return;
      } 
      if(this.stepBool){ //还是处于上一步回来的阶段    
        this.active++;
      }else{//正常往下走
        this.active++;
        this.finalStep++;
      }
      console.log("当前的this.active",this.active);
      if(this.active===8){
        this.active=0;
        this.finalStep=0;
        this.resetData();
      }
     // console.log("step",this.active);
      switch(this.active){
        case 1: 
        if(this.importBool){
          this.reset();
          this.imortBool=false;
        }
          this.setAreaName(); // 确保这是一个方法调用
          this.numstep=0;
          break;
        case 2:
        this.$message({
            type: 'info',
            message: '请在地图上画边界，双击左键结束画图'
          }); 
          this.isDrawing=true;
          this.toggleDrawing();  
          this.setBoundary(); // 确保这是一个方法调用
          break;
        case 3:
          this.isDrawing=true;//在监听事件中画一个边界就关闭了，这里再次启用
          this.toggleDrawing(); 
          this.showCascader = true ; // 确保这是一个方法调用
          if(this.stepBool){
            this.rolldrawBoundarySecond();
          }
          this.$message({
              type: 'info',
              message: '请在地图上画对应子区域，双击左键结束画图'
            });   
            break;
        case 4:
        // console.log("entrancesAndExitsCopy",this.entrancesAndExitsCopy);
        //         if(this.drawLineBool){//线段需要重新选择
        //           this.entrancesAndExits=[];
        //           this.selectList=[];
        //           this.addSelect();//可行
        //         }
        //         console.log(this.extrancesAndExits);
        //         this.entrancesAndExits.forEach((line=>{
        //           // line.setMap(null);
        //           line.setMap(this.map);
        //         }))
                this.childset()//用来实现上一步圈选后的子区  
                //  if(!this.steepBool){
                //   this.pointsReback=this.pointsRebackcopy;
                // }
                this.inandout = true;
                this.showCascaderButton=false; // 确保这是一个方法调用
                console.log("selectList",this.selectList);
          break;
        case 5:
          // this.handleSubdivide(); // 确保这是一个方法调用
          if(this.stepBool){
            console.log("回复画子区域switch");
            console.log(this.intersectingLinePrepared);
              // this.drawPolyline();
              this.showPolygon(this.ret);
              this.getAreaCentPoints(this.ret);
              this.drawPolyline();
              this.subdivideRegion = true; 
              this.inandout = false;
            }else{
              console.log("正常下步")
              this.handleSubdivide(); // 确保这是一个方法调用
              this.inandout = false;

            }
          this.showCascaderButton=false;
          break;
        case 6:
        this.$confirm('您确定要导出geojson格式的数据吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            // 当点击确定时，激活某个函数
            this.exportData(); // 确保这是一个方法调用
                this.subdivideRegion=false;
                // this.resetData();
            this.$message({
                type: 'success',
                message: '导出已完成'
            });
            this.active++;
        }).catch(() => {
            // 当点击取消时，减少 active 的值
            this.active--;
            this.$message({
                type: 'info',
                message: '导出已取消'
            });
        });
        // this.exportData(); // 确保这是一个方法调用
        //         this.subdivideRegion=false;
        //         this.resetData();
          break;
        case 7:
          break;
        case 8:
          this.resetData();
        // this.resetData();

       }   
       if(this.active===this.finalStep+1){
        this.stepBool=false;
        // this.drawLineBool=false;
        console.log("53423189545",this.stepBool);
       }
       if(this.active===this.finalStep){
        this.drawLineBool=false;
       }
    },
    beforeUpload(file) {
      // 检查文件类型
      const isGeoJSON = file.type === 'application/json' || file.name.endsWith('.geojson');
      if (!isGeoJSON) {
        this.$message.error('上传文件只能是 GeoJSON 格式!');
      }
      return isGeoJSON;
    },
    handleFileChange(file, fileList) {
      // 更新文件列表
      this.fileList = fileList;

      const reader = new FileReader();
      reader.onload = (e) => {
        console.log("JSON.parse(e.target.result)",JSON.parse(e.target.result));
        // try {
          const geojson = JSON.parse(e.target.result);
          this.addGeoJSONToMap(geojson);
        // } catch (error) {
        //   this.$message.error('文件解析失败');
        // }
      };
      reader.readAsText(file.raw); // 读取文件内容
    },
    addGeoJSONToMap(geojson) {
      // 检查传入的 GeoJSON 数据是否有效
      if (!geojson || geojson.type !== "FeatureCollection") {
        console.error("Invalid GeoJSON data");
        return;
      }

      // 遍历每个 Feature
      geojson.features.forEach(feature => {
        if (feature.geometry.type === "Polygon") {
          const coordinates = feature.geometry.coordinates[0]; // 获取多边形坐标

          // 创建多边形对象
          const polygon = new AMap.Polygon({
            path: coordinates.map(coord => new AMap.LngLat(coord[0], coord[1])), // 转换为 AMap.LngLat 格式
            fillColor:'#00b0ff',
            strokeColor:'#80d8ff',
            strokeWeight: 2, // 边界宽度
            fillOpacity: 0.5 // 填充透明度
          });

          // 将多边形添加到地图
          polygon.setMap(this.map);
          this.overlays.push(polygon);
        } else if (feature.geometry.type === "LineString") {
          const coordinates = feature.geometry.coordinates; // 获取线坐标

          // 创建折线对象
          const polyline = new AMap.Polyline({
            path: coordinates.map(coord => new AMap.LngLat(coord[0], coord[1])), // 转换为 AMap.LngLat 格式
            strokeColor: "#4F46E5", // 线条颜色
            strokeWeight: 2, // 线条宽度
            strokeOpacity: 0.5 // 线条透明度
          });

          // 将折线添加到地图
          polyline.setMap(this.map);
          this.drawLine.push(polyline);
        }
      });

      console.log("GeoJSON features added to the map");
      this.importBool=true;
    },
    onInput() {
      // 处理输入事件
      console.log(this.searchQuery);
    },
    onSelect(e) {
      // 选择地点后设置城市并查询
      this.placeSearch.setCity(e.poi.adcode);
      this.placeSearch.search(e.poi.name); // 根据选中的地点名称进行查询

      // 输出选择的信息
      console.log(`Adcode: ${e.poi.adcode}, Name: ${e.poi.name}`);

      // 更新地图中心坐标
      const location = e.poi.location; // 获取选中地点的坐标
      if (location) {
        this.map.setCenter([location.lng, location.lat]); // 更新地图的中心
          // 添加新的标记
          const marker = new AMap.Marker({
          position: location,
          title: e.poi.name,
        });
        marker.setMap(this.map);
        this.markers.push(marker); // 存储标记
      } else {
        console.error("未能获取到有效的坐标");
      }
      this.clearMarkers();
    },
    clearMarkers() {
      // 清除之前的所有标记
      this.markers.forEach(marker => {
        marker.setMap(null); // 从地图中移除标记
      });
      console.log(this.markers);
      this.markers = []; // 清空标记数组
    },
    resetData() {
      this.reset();
      //this.draw();
      this.drawingManager.close(true); // 关闭并清除覆盖物
      this.showCascader = false;
      this.showCascaderButton = false;
      this.inandout = false;
      this.subdivideRegion = false;
      this.choicevalue = ""; // 0, 1, 2
      this.areaName = "";
      this.row = "";
      this.column = "";
      this.base = 0; // 记录点的基础的
      this.labeledPoints = new Set(); // 用于存储已添加标签的点
      this.areaNamBoundary = "";
      this.areaNameAisle = "";
      this.areaNameEntranceExit = "";
      this.areaNameSubarea = "";
      this.cascaderValue = "";
      this.areaCenterPoints = [];
      this.polygonPoints = [];
      this.rectangles = []; // 用于存储命名后的 area 的矩形数组，子区细分部分
      this.polygonData = [];
      //this.map = null;
      this.overlays = [];
      // this.drawingManager = null;
      this.styleOptions = {
        strokeColor: "red",
        fillColor: "red",
        strokeWeight: 3,
        strokeOpacity: 0.8,
        fillOpacity: 0.6,
        strokeStyle: 'solid'
      };
      this.ret = {};
      this.retTwo = {};
      this.selectedLocation = [];
      this.locations = [
        { value: 'location1', label: '位置 1' },
        { value: 'location2', label: '位置 2' },
        { value: 'location3', label: '位置 3' },
      ];
      this.temporary = [];
      this.allPoints = []; // data 中定义 polygons 数组
      this.pointsReback = [];
      this.pointsRebackcopy = [];
      this.selectList = [{ selected: null }]; // 初始选择器
      this.childrenoptions = [ // 可选项
        { value: 'option1', label: '1' },
        { value: 'option2', label: '2' },
        { value: 'option3', label: '3' },
      ];
      this.options = [
        {
          value: 'A',
          label: '手动圈选',
          children: [
            { value: 'A1', label: '吸附边缘' },
            { value: 'A2', label: '不吸附' }
          ]
        },
        {
          value: 'B',
          label: '自动划分',
        }
      ];
      this.textLabels = [];
      this.textCenterLabels=[];
      this.activeName = ''; // 默认激活的标签
      this.labelVisible = true; // 用于地图标签初始化 显示
      this.lastTextNumber = 0; // 在回撤时，需要用来确定需要回撤标签的数量
      this.lastLineNumber=0;
      this.lastChildpolygonNumber=0;
      this.intersectingLine = [];
      this.intersectingLinePrepared=[];
      this.active = 0;
      this.finalStep=0;
      this.boundary = []; // 单独用来存储导出的数据。边界
      this.aisle = []; // 过道
      this.exit = []; // 出入口, 这个好弄 selectList
      this.subarea = []; // 细分区域.
      this.numstep = 0; // 用来区分进栈的步骤
      this.uploadUrl = ''; // 上传地址 (可以留空，因为我们不上传到服务器)
      this.fileList = []; // 存储选择的文件
      this.drawLine=[];
      this.isDrawing=true;
      this.entrancesAndExits=[];
    }
  }
};
</script>

<style scoped>
/* body, html {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: "微软雅黑";
} */
 .center-panel{
  position: absolute;  
  top: 20px; /* 距离顶部10px */
  left: 50%; /* 先将左侧设置为50% */
  transform: translateX(-50%); /* 向左移动自身宽度的一半，实现水平居中 */
  font-size: 26px;
  z-index:1000;
 }
.control-panel{
  position: absolute;
  top: 10px; /* 距离顶部10px */
  left: 10px; /* 距离左侧10px */
  z-index: 1000; /* 确保控件在最上层 */
}
.finishChild{
  position: absolute;
  bottom: 10px; /* 距离顶部10px */
  right: 10%; /* 距离左侧10px */
  z-index: 1000; /* 确保控件在最上层 */
}
.el-tabs__item {
    padding: 0 20px;
    height: 40px;
    box-sizing: border-box;
    line-height: 40px;
    display: inline-block;
    list-style: none;
    font-size: 24px;
    font-weight: 500;
    color: #303133;
    position: relative;
}
#app{
  display: flex; /* 使用 Flexbox 布局 */

}
#allmap {
  /* width: 108rem; */
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
#result {
  width: 100%;
  font-size: 12px;
}
dl, dt, dd, ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}
p {
  font-size: 12px;
}
dt {
  font-size: 14px;
  font-family: "微软雅黑";
  font-weight: bold;
  border-bottom: 1px dotted #000;
  padding: 5px 0 5px 5px;
  margin: 5px 0;
}
dd {
  padding: 5px 0 0 5px;
}
li {
  line-height: 28px;
}
.elbutton{
  width: 229px;
  height: 60px;
  font-size: 30px;
}
.select-box {
  position: absolute; /* 绝对定位选择框 */
  top: 20px; /* 根据需要调整位置 */
  width:13%;
  left: 20px; /* 根据需要调整位置 */
  z-index: 1000; /* 确保选择框在地图之上 */
  background: white; /* 设置背景色 */
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}
/* .input-row-column{
  width:40px;
} */
.input-boxx {
  position: absolute; /* 绝对定位选择框 */
  top: 20px; /* 根据需要调整位置 */
  left: 16%; /* 根据需要调整位置 */
  z-index: 1000; /* 确保选择框在地图之上 */
  background: white; /* 设置背景色 */
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  width: 10%; /* 设置宽度，根据需要调整 */
  /* 或使用 max-width: 200px; */
}
.input-box {
  border: 1px solid #dcdfe6; /* 边框颜色 */
  top: 20px; /* 根据需要调整位置 */
  right: 20%; /* 根据需要调整位置 */
  z-index: 1000; /* 确保选择框在地图之上 */
  border-radius: 4px; /* 圆角边框 */
  padding: 16px; /* 内边距 */
  margin: 16px 0; /* 外边距 */
  background-color: #f9f9f9; /* 背景颜色 */
  width: 100px; /* 设置宽度，根据需要调整 */
  /* 或使用 max-width: 200px; */
}
.input-row-column {
  margin-bottom: 12px; /* 控件之间的间距 */
}
.searchdiv{
  position: absolute; /* 绝对定位选择框 */
  top: 20px; /* 根据需要调整位置 */
  left: 2rem; /* 根据需要调整位置 */
  z-index: 1000; /* 确保选择框在地图之上 */
  display: flex; /* 使用 Flexbox 布局 */
  align-items: center; /* 垂直居中对齐 */
 /* background: white; 设置背景色 */
  padding: 5px;
  border-radius: 4px;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); */
}
.search-label {
  margin-right: 10px; /* 设置标签和输入框之间的间距 */
    font-size: 18px; /* 设置标签的字体大小 */
    font-weight: bold; /* 可选：加粗字体 */}
.steps{
  position: absolute; /* 绝对定位选择框 */
  top: 20px; /* 根据需要调整位置 */
  right: 2rem; /* 根据需要调整位置 */
  z-index: 1000; /* 确保选择框在地图之上 */
  background: white; /* 设置背景色 */
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}
/* #next-step{
  position: absolute; 
  top: 40%;
  right: 2rem; 
  z-index: 1000; 
  background: white; 
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
} */

#next-step {
  display: flex;            /* 使用 Flexbox 布局 */
  flex-direction: column;   /* 竖向排列 */
  align-items: flex-end;    /* 将按钮对齐到右侧 */
  position: absolute;        /* 绝对定位 */
  right: 2rem;              /* 距离右侧的距离 */
  top: 40%;                /* 距离顶部的距离 */
  gap: 10px;                /* 按钮之间的间距 */
}
.large-font {
  font-size: 38px; /* 增加区域名称的字体大小 */
}
.el-select {
    display: inline-block;
    position: relative;
    margin: 5px;                /* 设置外边距 */
    padding: 8px 12px;          /* 设置内边距 */
    background-color: #fff;     /* 设置背景颜色 */
    border-radius: 4px;         /* 设置边框圆角 */
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 添加阴影效果 */
}
.el-dialog__body {
    padding: 10px 20px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
}
.button-container {
  display: flex;            /* 使用 Flexbox 布局 */
  flex-direction: column;   /* 竖向排列 */
  align-items: flex-end;    /* 将按钮对齐到右侧 */
  position: absolute;        /* 绝对定位 */
  right: 2rem;              /* 距离右侧的距离 */
  top: 60%;                /* 距离顶部的距离 */
  gap: 10px;                /* 按钮之间的间距 */
}
.custom-button {
  width: 7rem;          /* 设置按钮的固定宽度 */
  /* 或者使用百分比宽度，例如： */
  /* width: 100%; */
}
.upload-demo{
  /* top:600px; */
  position: absolute;
  top:47%;
  right: 2rem; 
  height:20px;
  bottom: 20px;
  z-index: 1000;
}
.reb{
  position: absolute;
  top:52%;
  right: 2rem; 
  height:20px;
  bottom: 20px;
  z-index: 1000;
}
.el-dialog {
    position: absolute; /* 改为绝对定位 */
    left: 0;           /* 设置左边距为 0 */
    top: 50%;          /* 垂直居中 */
    transform: translateY(-50%); /* 通过平移实现垂直居中 */
    margin: 0;        /* 移除默认的 margin */
    background: #FFF;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
    box-sizing: border-box;
    width: 30%;
}
.floating-panel {
  position: absolute;
  top: 20px; /* 距离顶部的距离 */
  left: 20px; /* 距离左侧的距离 */
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000; /* 确保在地图之上 */
}

.select-item {
  margin-bottom: 10px;
}

.dialog-footer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.footer-buttons {
  display: flex; /* 使用 Flexbox */
  gap: 10px; /* 按钮之间的间距 */
}
.el-select-dropdown__item {
  text-align: center;
}
.custom-select {
  width: 150px; /* 设置为你需要的宽度 */
}
.in-out-title{
  font-size: 18px; /* 设置字体大小 */
  font-weight: bold; /* 设置字体加粗 */
  margin-bottom: 20px; /* 增加标题与其他内容之间的间距 */
  text-align: center;
}
.box-card{
  position: absolute; 
  bottom: 20px; 
  left: 20px; 
  z-index: 1000;
  width: 200px;
}
.search-input{
  height: 20px; /* 设置输入框高度 */
    width: 300px; /* 设置输入框宽度 */
    padding: 5px; /* 内边距以增加舒适感 */
    font-size: 16px; /* 字体大小 */
    border: 1px solid #ccc; /* 边框样式 */
    border-radius: 4px; /* 圆角边框 */
}
</style>